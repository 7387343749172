import Storage from "@/util/Storage";
import {getUserCount} from "@/Api/Platform/bascs";
import { getExpIncome,getShopIncome } from "@/views/PlatformAdmin/Server"

// 查询平台用户量
export function getPlatformUser() {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    return Promise.resolve(getUserCount({ userId,token }).then(res=>{
        return res.data
    }))
}

// 头部收入 及 用户量
export function getIncome() {
    return Promise.all([ getPlatformUser(),getShopIncome(),getExpIncome() ]).then(res=>{
        let data = {
            addUser:0,
            expMoney:0,
            expOrderCount:0,
            shopMoney:0,
            shopOrderCount:0,
        }
        Array.isArray(res) && res.forEach((item,index)=>{
            if ( index == 2 ){
                data.expMoney = item.amount || 0
                data.expOrderCount = item.orderCount || 0
            }else if (index == 1){
                data.shopMoney = item.storeFee || 0
                data.shopOrderCount = item.orderCount || 0
            }else{
                data.addUser = item.addUser || 0;
            }
        })
        return data;
    })
}
