
// 头部信息
export const headData = [
    {
        title:"平台用户量",
        value:"0 人",
        path:"/platformUser"
    },{
        title:"今日外卖",
        value:"0 元",
        path:"/bikeList?state=shop"
    },{
        title:"今日代取",
        value:"0 元",
        path:"/bikeList?state=exp"
    },
]

// 九宫格
export const menusList = [
    {
        icon:require("@/views/PlatformAdmin/Img/notice.png"),
        value:"公告管理",
        path:"/noticeAdmin"
    },{
        icon:require("@/views/PlatformAdmin/Img/functionAdmin.png"),
        value:"校区管理",
        path:"/schoolAdmin"
    },{
        icon:require("@/views/PlatformAdmin/Img/module.png"),
        value:"校区模块",
        path:"/moduleList"
    },{
        icon:require("@/views/PlatformAdmin/Img/banner.png"),
        value:"轮播图",
        path:"/bannerAdmin"
    },{
        icon:require("@/views/PlatformAdmin/Img/exp.png"),
        value:"代取收益",
        path:"/platformIncome?state=exp"
    },{
        icon:require("@/views/PlatformAdmin/Img/takeout.png"),
        value:"外卖收益",
        path:"/platformIncome?state=shop"
    },
]
